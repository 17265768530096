import React, { useState } from 'react';

import {
  Navbar, Nav, NavDropdown
} from 'react-bootstrap';
import {css} from '@emotion/core';
import logo from '../images/logo.png';

const style = {
  menuItemBase: css`
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    background-color: #f5f6f5 !important;
    &,a {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
    &:hover,a:hover {
      color: rgba(0,0,0,0.9)!important;
    }
  `,
  menuItemInactive: css`
    &,&>a {
      color: #949e94 !important;
    }
  `,
  menuItemActive: css`
    &,&>a {
      color: #283c28 !important;
    }
  `,
  navDropdown: css`
    & a {
      padding-left: 0 !important;
      padding-right: 0 !important;
      &::after {
        display: none !important;
      }
    }
    .dropdown-menu {
      margin-left: -5px;
      margin-top: -5px;
      padding-top: 34px;
      background-color: #f5f6f5;
      border: none;
      border-radius: 0;
      text-align: center;
      min-width: 8.5rem !important;
    }
    
    .dropdown-menu a {
      padding-top: 6px;
      padding-bottom: 6px;
      width: 99%;
      border: none;
    } 
  `
};

const isCurrentPage = (href, currentPath) => {
  return `${href}${href.endsWith('/') ? '' : '/'}` === 
    `${currentPath}${currentPath.endsWith('/') ? '' : '/'}`
};

export default ({currentPath}) => {
  const [focusOnTechnology, setFocusOnTechnology] = useState(false);

  const createMenuItem = (type, href, text) => {
    const itemStyle = css`
      ${style.menuItemBase};
      ${isCurrentPage(href, currentPath) ?
        style.menuItemActive :
        style.menuItemInactive
      };
    `;
    switch (type) {
      case 'link':
        return <Nav.Link href={href} css={itemStyle}>{text}</Nav.Link>;
      case 'item':
        return <NavDropdown.Item href={href} css={itemStyle}>{text}</NavDropdown.Item>;
      case 'a':
        return <div css={css`
          ${itemStyle};
          padding-top: 8px;
          padding-bottom: 8px;
        `}>
          <a href={href} rel="noopener noreferrer" target="_blank">{text}</a>
        </div>;
      default:
        break;
    }
  };

  return (
    <header className="d-none d-sm-block">
      <Navbar
        className={`header-nav`}
        expand="sm"
        css={css`
          background-color: #f5f6f5;
        `}
      >
        <Navbar.Brand href="/">
          <img
            alt="logo"
            src={logo}
            width="76"
            height="76"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          css={css`
            margin-right: 20px;
          `}
        >
          <Nav className="mr-auto">
          </Nav>
          <Nav
            className="d-flex flex-wrap"
            css={css`
            margin-top: 4px;
            `}
          >
            {createMenuItem('link', '/', 'トップ')}
            {createMenuItem('link', '/service', '開発・制作')}
            <NavDropdown
              title="製品・技術"
              onClick={e => {
                if (e.target.textContent !== '製品・技術') {
                  return;
                }
                window.location.href = '/technology';
              }}
              // onClick={(e) => console.log(e.target)}
              id="basic-nav-dropdown"
              css={
                [
                  style.menuItemBase,
                  currentPath.startsWith('/technology/') ?
                    style.menuItemActive :
                    style.menuItemInactive,
                  style.navDropdown
                ]
              }
              show={focusOnTechnology}
              onMouseEnter={() => setFocusOnTechnology(true)}
              onMouseLeave={() => setFocusOnTechnology(false)}
              onFocus={() => setFocusOnTechnology(true)}
              onBlur={() => setFocusOnTechnology(false)}
            >
              {createMenuItem('item', '/technology/soundtope', 'soundtope')}
              {createMenuItem('item', '/technology/sonicwalk', 'sonicwalk')}
              {createMenuItem('item', '/technology/omnion', 'omnion')}
              {createMenuItem('item', '/technology/mimicafe', 'mimicafe')}
              {createMenuItem('a', 'https://beatcamera.com/', 'beatcamera')}
              {createMenuItem('item', '/technology/others', 'その他の技術')}
            </NavDropdown>
            {createMenuItem('link', '/works', '実績')}
            {createMenuItem('link', '/member', 'アーティスト')}
            {createMenuItem('link', '/about', '会社概要')}
            {createMenuItem('a', 'https://docs.google.com/forms/d/e/1FAIpQLSdZnApHPzqfK3BkEX9k24ilOIIKpDx5yMxqZd7uqzlhLuqzcQ/viewform?usp=sf_link', '問い合わせ')}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};
