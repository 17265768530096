import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Global, css} from '@emotion/core';

import Footer from './footer';
import Header from './header';
import HeaderMobile from './header-mobile';

export const bp = Object.assign(
  ...Object.entries({
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200
  }).map(([label, width]) => ({[label]: `@media (min-width: ${width}px)`}))
);

const materialIconStyle = css`
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  // src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    // url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    // url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    // url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
`;

const globalStyle = css`
@import url(https://indestructibletype.com/fonts/Jost.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

body {
  font-family: 'Jost', 'Noto Sans Japanese', sans-serif;
  color: #1e281e;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: -0.01em;
  text-align: justify;
}

h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 30px;
  ${bp.sm} {
    margin-top: 0;
  }
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  border-left: 6px solid #00B45A;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 14px;
  margin: 40px -10px 25px -20px;
  ${bp.sm} {
    margin: 40px -10px 25px 0;
    padding-left: 10px;
  }
}

h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 20px 0;
}

ul {
  list-style: none;
  padding-left: 0;
  font-weight: 500;
}

li {
  margin: 1.5em 0;
  ${bp.sm} {
    margin: 0.8em 0;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.green-border-left {
  border-left: 8px solid #00B45A;
  padding-left: 20px;
  margin-left: -20px;
  ${bp.sm} {
    margin-left: 3px;
    margin-right: -3px;
  }
}

.no-padding {
  padding: 0;
}

.inline-icon {
  font-size: 1.4em;
  line-height: 1.4em;
  vertical-align: middle;
}

.text-gray {
  color: rgba(40, 40, 60, 0.3);
}

.background-gray {
  background-color: #F5F6F5;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
`;

export default ({
  pageTitle='', children, location,
  description='「アート × 産業 × 研究」をリンクさせるクリエイティブ集団。音・身体・生活・テクノロジーをキーワードに、あたらしい価値を創造します。',
  footerCss=''
}) => {

  const [alpha, setAlpha] = useState(0);
  useEffect(() => {setTimeout(() => setAlpha(1), 100)});

  const getPath = () => {
    return typeof window !== 'undefined' ?
      window.location.pathname :
      (typeof location !== 'undefined' ?
        location.pathname : '');
  }
  
  return (
    <div css={css`
      transition: opacity 0.7s;
      opacity: ${alpha}
    `}>
      <Global styles={materialIconStyle}/>
      <Global styles={globalStyle}/>
      <Helmet
        title={pageTitle + (pageTitle && ' | ') + 'coton | コトン | 東京藝術大学発ベンチャー'}
      >
        <meta name="description" content={description}/>
        <meta property="og:url" content="https://coton.jp"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="coton | コトン | 東京藝術大学発ベンチャー"/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content="https://coton.tech/coton_sns.png"/>
        <meta property="fb:app_id" content="2518370158417602"/>
        <meta name="twitter:card" content="summary_large_image"/> 
        <meta name="twitter:site" content="@coton_tech"/> 
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmPrhyRiZpuDkm0eAbegoXAqdhBXoQKPg"></script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-159331843-1"></script>
        <script src="/google-analytics.js"></script>
      </Helmet>


      {
        typeof window !== 'undefined' && (
          <>
            <Header currentPath={getPath()} />
            <HeaderMobile currentPath={getPath()} />
          </>
        )
      }

      <div css={css`
        margin-top: 55px;
        ${bp.xs} {
          margin-top: 0;
        }
      `}>
        {children}
      </div>

      <Footer footerCss={footerCss} />
    </div>
  );
};


