import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import {css} from '@emotion/core';
import {bp} from '../components/layout';

export default props => {
  return (
    <Container
      fluid
      css={css`
        padding: 50px 20px;
        ${bp.xs} {
          padding: 60px 20px 80px 20px;
        }
      `}
      className={props.className}
    >
      <Row>
        <Col md={1} className="d-none d-sm-block"></Col>
        <Col md={10}>
          {props.children}
        </Col>
        <Col md={1} className="d-none d-sm-block"></Col>
      </Row>
    </Container>
  );
};
