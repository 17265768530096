/* eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-static-element-interactions
*/

import React, { useState } from 'react';
import {css} from '@emotion/core';
import {Link} from 'gatsby';

import logo from '../images/logo.png';
import iconTwitter from '../images/twitter.png';
import iconFacebook from '../images/facebook.png';

const isCurrentPage = (href, currentPath) => {
  return `${href}${href.endsWith('/') ? '' : '/'}` === 
    `${currentPath}${currentPath.endsWith('/') ? '' : '/'}`
};

const createItem = (currentPath, label, url, options={}) => {
  const inner = <div css={css`
    color: #283C28;
    font-weight: 600;
    opacity: ${url && isCurrentPage(url, currentPath) ? '1.0' : '0.5'};
    font-size: 16px;
    margin:
      ${options.indent ? '0.2vh' : '1.2vh'}
      0
      ${options.indent ? '0.2vh' : '0.6vh'}
      ${options.indent ? '20px' : '0px'};
  `}>
    {label}
  </div>;
  const content = url ? (
    url.startsWith('http') ?
      <a href={url} target="_blank" rel="noopener noreferrer">{inner}</a> :
      <Link to={url}>{inner}</Link>
  ) : <div>{inner}</div>;
  return <div key={label}>{content}</div>;
};

export default ({currentPath}) => {
  const [open, setOpen] = useState(false);
  return (
    <header className="d-block d-sm-none">
      {/* header */}
      <div
        css={css`
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 55px;
          background-color: #F5F6F5;
          z-index: 200;
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 5px;
            top: 0px;
            padding: 5px 10px;
            cursor: pointer;
          `}
          onClick={() => {
            setOpen(true);
          }}
        >
          <i
            className="material-icons inline-icon"
            css={css`
              margin-top: 1px;
              margin-right: 5px;
              font-size: 32px;
            `}
          >
            menu
          </i>
        </div>
        <Link to='/'>
          <img
            alt="logo"
            src={logo}
            width="50"
            height="50"
            css={css`
              position: absolute;
              left: 50%;
              margin-top: 2px;
              margin-left: -25px;
              text-align: center;
            `}
          ></img>
        </Link>
      </div>

      {/* drawer */}
      <div

        css={css`
          position: fixed;
          left: ${open ? '0px' : '-300px'};
          width: 250px;
          height: 100vh;
          max-width: 75vw;
          transition: left 0.3s;
          transition-timing-function: ease-out;
          top: 0;
          background-color: #fff;
          z-index: 400;
          box-shadow: 10px 10px 30px 10px rgba(0,0,0,${open ? '0.4' : '0'});
        `}
      >
        <div
          css={css`
            position: absolute;
            right: 30px;
            top: 20px;
          `}
        >
          <span className="material-icons"
            css={css`
              font-size: 32px;
              color: #283C28;
              opacity: 0.5;
            `}
            onClick={() => {
              setOpen(false);
            }}
          >close</span>
        </div>
        <div css={css`
          padding: 25px 40px 30px 40px;
        `}>
          <Link to='/'>
            <img
              alt="logo"
              src={logo}
              width="40"
              height="40"
              css={css`
                margin: -10px 0 10px -3px;
              `}
            ></img>
          </Link>
          {
            [
              ['トップ', '/'],
              ['開発・制作', '/service'],
              ['製品・技術', '/technology'],
              ['soundtope', '/technology/soundtope', {indent: true}],
              ['sonicwalk', '/technology/sonicwalk', {indent: true}],
              ['omnion', '/technology/omnion', {indent: true}],
              ['mimicafe', '/technology/mimicafe', {indent: true}],
              ['beatcamera', 'https://beatcamera.com/', {indent: true}],
              ['その他の技術', '/technology/others', {indent: true}],
              ['実績', '/works'],
              ['アーティスト', '/member'],
              ['会社概要', '/about'],
              ['問い合わせ', 'https://docs.google.com/forms/d/e/1FAIpQLSdZnApHPzqfK3BkEX9k24ilOIIKpDx5yMxqZd7uqzlhLuqzcQ/viewform?usp=sf_link']
            ].map(args => createItem(currentPath, ...args))
          }
        </div>
        <div css={css`
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 80px;
          background-color: #00B45A;
          padding: 25px 40px;
        `}>
          <a href="https://twitter.com/coton_tech" target="_blank" rel="noopener noreferrer">
            <img src={iconTwitter} alt="icon-twitter" />
          </a>
          <div css={css`
            display: inline-block;
            width: 24px;
          `}></div>
          <a href="https://www.facebook.com/coton.tech/" target="_blank" rel="noopener noreferrer">
            <img src={iconFacebook} alt="icon-facebook" />
          </a>
        </div>
        <div css={css`
          position: absolute;
          right: 35px;
          bottom: 32px;
          color: white;
          font-size: 10px;
          vertical-align: middle;
        `}>
          &copy; 2020 coton
        </div>
      </div>
      <div
        css={css`
          display: ${open ? 'block' : 'none'};
          position: fixed;
          z-index: 300;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: #fff;
        `}
        onClick={() => setOpen(false)}
      ></div>
    </header>
  );
};