import React from 'react';
import {css} from '@emotion/core';

import logo from '../images/logo.png';
import iconTwitter from '../images/twitter.png';
import iconFacebook from '../images/facebook.png';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

const linkStyle = css`
color: white;
&:hover {
  color: white;
  opacity: 0.8;
}
`;

const createLinks = links => links.map(([text, to]) => (
  <div
    key={text}
    css={css`
      margin-bottom: 6px;
    `}
  >
    {
      to.startsWith('http') ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          css={linkStyle}
      >{text}</a>
      ) : (
        <Link
          to={to}
          css={linkStyle}
        >{text}</Link>
      )
    }
  </div>
));

export default (footerCss) => (
  <footer>
    <Container
      fluid
      css={css`
        height: 200px;
        padding-top: 50px;
        background-color: #00B45A;
        color: white;
        font-size: 10px;
        ${footerCss}
      `}
      className='d-none d-sm-block'
    >
      <Row>
        <Col sm={1}></Col>
        <Col sm={8} css={css`
          display: flex;
        `}>
          <div css={css`
            margin-right: 5vw;
          `}>
            <img
              alt="logo"
              src={logo}
              width="152"
              height="152"
              css={css`
                margin-top: -25px;
                mix-blend-mode: hard-light;
              `}
            ></img>
          </div>
          <div css={css`
            width: 100px;
          `}>
            {
              createLinks([
                ['トップ', '/'],
                ['開発・制作', '/service'],
                ['製品・技術', '/technology'],
                ['実績', '/works'],
                ['アーティスト', '/member'],
              ])
            }
          </div>
          <div css={css`
            width: 100px;
          `}>
            {
              createLinks([
                ['soundtope', '/technology/soundtope'],
                ['sonicwalk', '/technology/sonicwalk'],
                ['omnion', '/technology/omnion'],
                ['mimicafe', '/technology/mimicafe'],
                ['beatcamera', 'https://beatcamera.com'],
              ])
            }
          </div>
          <div css={css`
            width: 100px;
          `}>
            {
              createLinks([
                ['その他の技術', '/technology/others'],
                ['会社概要', '/about'],
                ['問い合わせ', 'https://docs.google.com/forms/d/e/1FAIpQLSdZnApHPzqfK3BkEX9k24ilOIIKpDx5yMxqZd7uqzlhLuqzcQ/viewform?usp=sf_link']
              ])
            }
          </div>
        </Col>
        <Col sm={2}>
          <div css={css`
            text-align: right;
          `}>
            <a href="https://twitter.com/coton_tech" target="_blank" rel="noopener noreferrer">
              <img src={iconTwitter} alt="icon-twitter" />
            </a>
            <div css={css`
              display: inline-block;
              width: 24px;
            `}></div>
            <a href="https://www.facebook.com/coton.tech/" target="_blank" rel="noopener noreferrer">
              <img src={iconFacebook} alt="icon-facebook" />
            </a>
          </div>
          <div css={css`
            position: absolute;
            right: 15px;
            bottom: 18px;
          `}>
            &copy; 2020 coton
          </div>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
  </footer>
);